<template>
  <div class="versions-release-page">
    <b-card v-if="loading">
      <div class="d-flex justify-content-center">
        <b-spinner label="Loading..." />
      </div>
    </b-card>
    <b-card
      v-else
      class="versions-release-class"
    >
      <b-row>
        <b-col
          :cols="$ableTo('Edit Release Notes') ? '6' : '12'"
          xs="12"
        >
          <h4 class="card-title  float-left">
            Release Notes
          </h4>
        </b-col>

        <b-col
          :cols="$ableTo('Edit Release Notes') ? '6' : '12'"
          xs="12"
        >
          <div
            v-if="$ableTo('Edit Release Notes')"
            class="mb-1 text-right"
          >
            <b-button v-b-tooltip="'New version release'">
              <router-link
                class="text-white"
                :to="{ name: 'edit.release-notes' }"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="pointer-cursor"
                />
                New Release Notes
              </router-link>
            </b-button>
          </div>
        </b-col>

      </b-row>
      <div v-if="versions.length > 0">
        <div
          v-for="(version,index) in versions"
          :key="index"
        >
          <b-row class="version-wrapper p-1 pr-3 mb-2">
            <b-col
              class="mt-1"
              cols="12"
              v-html="version.text"
            />
            <b-col
              v-if="$ableTo('Edit Release Notes')"
              cols="12"
              class="text-right"
            >
              <b
                v-b-tooltip="'Edit Release Notes'"
                class="release-icon edit-release"
              >
                <router-link
                  :to="{ name: 'edit.release-notes', params:{
                    id: version.id
                  } }"
                >
                  <feather-icon
                    icon="Edit2Icon"
                  />
                  Edit
                </router-link>
              </b>
              <b
                v-b-tooltip="'Delete Release Notes'"
                class="release-icon delete-release"
                @click="Delete(version.id)"
              >
                <feather-icon
                  icon="XIcon"
                />Delete</b>
            </b-col>
          </b-row>
        </div>
      </div>
      <div
        v-else
        class="font-weight-bold"
      >
        No versions to list yet.
      </div>

    </b-card>

  </div>
</template>
<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      loading: false,
      directives: {
        'b-tooltip': VBTooltip,
      },
      versions: [],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    Delete(id) {
      this.$swal({
        title: 'Delete release version?',
        text: 'Would you like to delete this release version?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.$http.delete(this.$resources.releaseNotes.delete.replace(':id', id)).then(response => {
            const { data: { status } } = response

            switch (status) {
              case 200:
                this.load()
                break
              default:
                this.$notify({ type: 'error', title: 'Service Not Available', text: `CODE: ${status}` })
            }

            this.loading = false
          })
        }
      })
    },
    load() {
      this.loading = true
      this.$http.get(this.$resources.releaseNotes.index).then(response => {
        const { data: { status, data } } = response
        switch (status) {
          case 200:
            this.versions = data.rows
            break
          default:
            this.$notify({ type: 'error', title: 'Service Not Available', text: `CODE: ${status}` })
        }
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss" scoped>

.versions-release-page {
    .card
      {
        min-height: 900px;
        &:not(.versions-release-class) {
          .card-body {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
      }
    }
.versions-release-class {
    .version-wrapper {
        border:1px solid #ccc;
        border-radius: 15px;
    }
    .release-icon{
        color:black;
        cursor: pointer;
    }
}
</style>
